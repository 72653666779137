import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

import styles from "./hamburger.module.css";
import burger from "../../assets/hamburger.png";

const Hamburger = ({showBurger}) => {
  const [show, setShow] = useState(showBurger);
  const [popout, setPopout] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 730) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    if (show && document.getElementById("burger")) {
      document.getElementById("burger").addEventListener("mouseenter", () => {
        setPopout(true);
      });
      document
        .getElementById("container")
        .addEventListener("mouseleave", () => {
          setPopout(false);
        });
    }
  });

  if (showBurger) {
    return (
      <div
        className={popout ? styles.mainContainerFocused : styles.mainContainer}
        id="container"
      >
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            {show && <img src={burger} alt="" id="burger" />}
          </div>
          <div className={styles.menuContainer}>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={400}
              to="home"
              onClick={() => {
                setTimeout(() => {
                  setPopout(false);
                }, 200);
              }}
            >
              <p>Home</p>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={400}
              to="about"
              onClick={() => {
                setTimeout(() => {
                  setPopout(false);
                }, 200);
              }}
            >
              <p>About</p>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={400}
              to="stack"
              onClick={() => {
                setTimeout(() => {
                  setPopout(false);
                }, 200);
              }}
            >
              <p>Stack</p>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={400}
              to="projects"
              onClick={() => {
                setTimeout(() => {
                  setPopout(false);
                }, 200);
              }}
            >
              <p>Projects</p>
            </Link>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={400}
              to="contact"
              onClick={() => {
                setTimeout(() => {
                  setPopout(false);
                }, 200);
              }}
            >
              <p>Contact</p>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Hamburger;
