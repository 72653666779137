import React from "react";
import styles from "./contact.module.css";
import linkedin from "../../assets/linkedin.png";
import email from "../../assets/mail.png";
import github from "../../assets/github_different.png";
import callMe from "../../assets/call me.jpg";

const contact = () => {
  return (
    <div className={styles.mainContainer} id="contact">
      <div className={styles.content}>
        <div className={styles.getInTouch}>
          <h3>Get in touch!</h3>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.callMe}>
            <img src={callMe} alt="" />
          </div>
          <div className={styles.icons}>
            <div>
              <a href="https://www.linkedin.com/in/arnav-mundkur-167422b9/">
                <img src={linkedin} alt="" />
              </a>
            </div>
            <div>
              <a href="mailto:mundkurarnav@gmail.com">
                <img src={email} alt="" />
              </a>
            </div>
            <div>
              <a href="https://github.com/arnavmundkur95">
                <img src={github} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default contact;
