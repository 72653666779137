import React from "react";
import styles from "./stack.module.css";

const stack = () => {
  return (
    <div className={styles.mainContainer} id="stack">
      <div className={styles.content}>
        <div className={styles.title}>My Stack</div>
        <div className={styles.imageGrid}>
          <div className={styles.javascript} />
          <div className={styles.typescript} />
          <div className={styles.react} />
          <div className={styles.flutter} />
          <div className={styles.reactNative} />
          <div className={styles.python} />
          <div className={styles.dart} />
          <div className={styles.redux} />
          <div className={styles.expo} />
        </div>
      </div>
    </div>
  );
};

export default stack;
