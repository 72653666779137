import React from "react";
import styles from "./home.module.css";
// import profilePic from "../../assets/arnav.jpg";

const home = () => {
  return (
    <div className={styles.mainContainer} id='home'>
      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.imgContainer} />
          <div className={styles.homeText}>
            <h3>Arnav Mundkur</h3>
            <p>Mobile Developer & Rapid Prototyper</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default home;
