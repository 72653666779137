import React from "react";
import profilePic from "../../assets/arnavLogo.png";
import styles from "./header.module.css";
import { Link } from "react-scroll";

const header = () => {
  return (
    <div className={styles.navigationContainer}>
      <div className={styles.navigationContent}>
        <Link
          activeClass="active"
          spy={true}
          smooth={true}
          offset={0}
          duration={400}
          to="home"
        >
          <div className={styles.logoContainer}>
            <img src={profilePic} alt="" />
          </div>
        </Link>
        <div className={styles.links}>
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={400}
            to="about"
          >
            <p className={styles.linkText}>About</p>
          </Link>
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={400}
            to="stack"
          >
            <p className={styles.linkText}>Stack</p>
          </Link>
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={400}
            to="projects"
          >
            <p className={styles.linkText}>Projects</p>
          </Link>
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={400}
            to="contact"
          >
            <p className={styles.linkText}>Contact</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default header;
