import './App.css';
import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/about';
import Stack from './components/stack/stack';
import Projects from './components/projects/projects';
import Contact from './components/contact/contact';
import Hamburger from './components/hamburger/hamburger';
import React from 'react';

function App() {
  const [showBurger, setShowBurger] = React.useState(false);

  return (
    <div className='App'>
      <Header />
      <Hamburger showBurger={showBurger} />
      <Home />
      <About />
      <Stack />
      <Projects burgerControl={setShowBurger} />
      <Contact />
    </div>
  );
}

export default App;
