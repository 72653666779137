import React from 'react';
import routinelyLogo from '../../../assets/routinely_circular_logo.png';
import styless from './project.module.css';
import greenswappLogo from '../../../assets/greenswappLogo.png';
import crispLogo from '../../../assets/crispLogo.png';
import routinelyTasks from '../../../assets/files/Tasks at Routinely Arnav Mundkur.pdf';

const Project = ({ taskDescription, title, id, children }) => {
  const headerLogo = {
    routinely: routinelyLogo,
    greenswapp: greenswappLogo,
    crisp: crispLogo,
  };

  const links = {
    routinely: 'https://www.routinely.com',
    greenswapp: 'https://greenswapp.com',
    crisp: 'https://crisp.nl',
    thesis:
      'https://www.researchgate.net/publication/351244845_Have_you_tried_this_Designing_a_smartphone_application_to_support_sustainable_food_purchasing',
    reffleaux: 'https://www.youtube.com/watch?v=ySdvdfwa7O0',
    filter: 'https://github.com/arnavmundkur95/ColoredLetterFilter',
  };

  const taskDescriptions = {
    routinely:
      ' Routinely is an e-commerce skin-care startup, and during my 11 months as the sole mobile developer,' +
      ' I handled all the responsiblities regarding the app, save the design.' +
      ' I helped ideate and formulate new features, fixed production level bugs, managed' +
      ' the app store listings and collaborated closely with the PM on feature development' +
      ' and testing. I also introduced E2E tests using Detox and wrote a few GitHub workflows.',
    greenswapp:
      ' I did a three month internship at GreenSwapp, a carbon-tracking API service for' +
      ' supermarkets, food apps and food brands to estimate the climate impact of their products.' +
      ' While there, I worked on their B2B dashboard (Next.js) and B2C application (Expo). My (B2B) tasks' +
      ' involved updating the website, fixing production bugs, adding new features to the B2B dashboard' +
      ' and implementing new web-pages. With regards to the B2C app, I worked on new features and fixed' +
      ' bugs in production.',
    crisp:
      ' Crisp is an online grocery store that aims to make healthy, high quality food' +
      ' more easily available. As a full stack developer on a team of five, my responsibilities involve' +
      ' implementing new features that may require new API endpoints to be written, refactoring code, implementing' +
      ' new designs, introducing animations to the UI to improve the UX, and writing E2E tests.',
    thesis:
      '  I conducted my master thesis on designing and developing a behavior change application which' +
      ' helps people make sustainable decisions while doing their groceries. The application allows the user' +
      ' to set their own substitution goals, create shopping lists, and suggests sustainable alternatives to ' +
      ' conventional meat and dairy products they consume. I built the application using Flutter and used web-scraping' +
      ' to trawl local the websites of local supermarkets to implement a search functionality, and to suggest sustainable alternatives.',
    reffleaux:
      ' A group project which physically visualizes a UN databse of refugee movement between member states.' +
      ' My role within the project was of a full-stack developer. The back-end was programmed in Python and used SQL' +
      ' to query relevant data from the database, while the front-end was made using Processing (a Java library).' +
      ' The table had two RFID scanners and each country was outfitted with its own RFID tag. This allowed the visitor' +
      ' to choose an origin and destination country in which refugees saught refuge. The table had a rotatable edge which' +
      ' the visitor used to specify the year of the query.',
    filter:
      'I like messing around with code from time to time, and decided to try my hand at an image filter. The filter fetches' +
      " the value of every few pixels and displays a random character in the pixels's position instead of the pixel color values." +
      ' For a quick project like this, I like messing around in Processing, due to its handy functionality for image manipulation.',
  };

  const AdditionalTaskDescriptions = () => {
    if (id === 'routinely') {
      return (
        <p className={styless.taskDescriptions}>
          Read about some of the most noteable features and functionality I
          worked on at Routinely{' '}
          <span
            className={styless.hover}
            onClick={() => {
              window.open(routinelyTasks);
            }}>
            here.
          </span>
        </p>
      );
    }
    return <p></p>;
  };

  return (
    <div className={styless.container}>
      <div className={styless.description}>
        <img
          src={headerLogo[id]}
          style={styles[`${id}Logo`]}
          onClick={() => {
            window.open(links[id]);
          }}
        />
        <h3 style={styles.title}>{title}</h3>
        <p className={styless.taskDescriptions}>{taskDescriptions[id]}</p>
        <AdditionalTaskDescriptions />
      </div>
      <div
        style={styles.images}
        onClick={() => {
          if (Object.keys(links).includes(id)) {
            window.open(links[id]);
          }
        }}>
        {children}
      </div>
    </div>
  );
};

var styles = {
  title: { color: 'white', fontSize: '2em' },
  images: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  routinelyLogo: {
    height: '150px',
    width: '150px',
    paddingLeft: '0em',
  },
  greenswappLogo: {
    width: '50%',
    paddingLeft: '0em',
  },
  crispLogo: {
    width: '40%',
  },
};

export default Project;
