import React, { useEffect, useState } from 'react';

import styles from './projects.module.css';
import sus1 from '../../assets/sus1.jpg';
import sus2 from '../../assets/sus2.jpg';
import sus3 from '../../assets/sus3.jpg';
import table from '../../assets/table.jpeg';
import wallpaper from '../../assets/wallpaper.png';
import routinely1 from '../../assets/routinely1.png';
import routinely2 from '../../assets/routinely2.png';
import routinely3 from '../../assets/routinely3.png';
import crisp1 from '../../assets/crisp1.png';
import crisp2 from '../../assets/crisp2.png';
import crisp3 from '../../assets/crisp3.png';
import greenswapp1 from '../../assets/greenswapp_offset.svg';
import greenswapp2 from '../../assets/greenswapp_app.jpeg';
import Project from './components/project';

const Projects = ({ burgerControl }) => {
  const [susModal, setShowSusModal] = useState(false);
  const [sensoreeModal, setShowSensoreeModal] = useState(false);
  const [reffleauxModal, setShowReffleauxModal] = useState(false);
  const [ringzzModal, setShowRingzzModal] = useState(false);
  const [wallpaperModal, setShowWallpaperModal] = useState(false);

  useEffect(() => {
    if (
      susModal ||
      sensoreeModal ||
      reffleauxModal ||
      ringzzModal ||
      wallpaperModal
    ) {
      burgerControl(false);
    } else {
      burgerControl(true);
    }
  }, [susModal, sensoreeModal, reffleauxModal, ringzzModal, wallpaperModal]);

  return (
    <div className={styles.mainContainer} id='projects'>
      <div className={styles.heading}>Some of my work...</div>
      <div className={styles.projectContainer}>
        {/* Crisp */}
        <Project title={'Full-stack Developer'} id={'crisp'}>
          <div className={styles.routinelyGrid}>
            <img src={crisp2} alt='' className={styles.routinely2} />
            <img src={crisp3} alt='' className={styles.routinely3} />
            <img src={crisp1} alt='' className={styles.routinely1} />
          </div>
        </Project>

        {/* Routinely */}
        <Project title={'Mobile Developer'} id={'routinely'}>
          <div className={styles.routinelyGrid}>
            <img src={routinely2} alt='' className={styles.routinely1} />
            <img src={routinely3} alt='' className={styles.routinely3} />
            <img src={routinely1} alt='' className={styles.routinely2} />
          </div>
        </Project>

        {/* GreenSwapp */}
        <Project
          title={'Front-end & Mobile Developer Intern'}
          id={'greenswapp'}>
          <div className={styles.greenswappGrid}>
            <img src={greenswapp1} alt='' className={styles.greenswapp1} />
            <img src={greenswapp2} alt='' className={styles.greenswapp2} />
          </div>
        </Project>

        {/* Master Thesis */}
        <Project title={'Master thesis: Sustainable shopper'} id={'thesis'}>
          <div className={styles.susImages}>
            <img src={sus1} alt='' className={styles.sus1} />
            <img src={sus2} alt='' className={styles.sus2} />
            <img src={sus3} alt='' className={styles.sus3} />
          </div>
        </Project>

        {/* Reffleaux */}
        <Project title={'Reffleaux'} id={'reffleaux'}>
          <div className={styles.image}>
            <img src={table} alt='' />
          </div>
        </Project>

        {/* Hobby project: Image filter */}
        <Project title={'Hobby project: Image filter'} id={'filter'}>
          <div className={styles.wallpaper}>
            <img src={wallpaper} alt='' />
          </div>
        </Project>
      </div>
    </div>
  );
};

export default Projects;
