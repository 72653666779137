import React from 'react';
import styles from './about.module.css';

const about = () => {
  return (
    <div className={styles.mainContainer} id='about'>
      <div className={styles.description}>
        <p>
          Passionate about marrying my love of sustainability with technology.{' '}
          Interested in behavior-change technologies and ecological foot-print
          reduction. Experienced in developing digital products from the
          ideation phase to launch, with more than a year of experience
          developing mobile applications.
        </p>
      </div>
    </div>
  );
};

export default about;
